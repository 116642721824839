import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import "../layout.css"

export default function Poetry() {
    return (
        <GalleryBackground title="Poetry">
            <GalleryGridFancy imgArray={[]}/>
        </GalleryBackground>
    )
}
